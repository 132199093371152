import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import "./navbar.scss"

const isActive = ({ isCurrent }) => {
  return isCurrent
    ? { className: "nav-link active" }
    : { className: "nav-link" }
}

const ExactNavLink = props => <Link getProps={isActive} {...props} />

const Navbar = () => {
  const data = useStaticQuery(graphql`
    query NavbarData {
      prismicCvswapoutHeader {
        data {
          links {
            href
            label
          }
          logo {
            alt
            url
          }
          name
        }
      }
    }
  `)

  return (
    <nav className="navbar navbar-expand-md navbar-light bg-white">
      <div className="container">
        <Link to="/" className="navbar-brand">
          {/*<img
            src={data.prismicCvswapoutHeader.data.logo.url}
            alt={
              data.prismicCvswapoutHeader.data.logo.alt
                ? data.prismicCvswapoutHeader.data.logo.alt
                : "CV Swapout logo"
            }
          />*/}
          <StaticImage src="./../../../images/logo.png"
          alt={
            data.prismicCvswapoutHeader.data.logo.alt
              ? data.prismicCvswapoutHeader.data.logo.alt
              : "CV Swapout logo"
          }
          className="logo"
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#main-navbar"
          aria-controls="main-navbar"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="main-navbar">
          <ul className="navbar-nav ms-auto mb-2 mb-md-0">
            {data.prismicCvswapoutHeader.data.links.map((link, index) => (
              <li className="nav-item" key={index}>
                <ExactNavLink to={link.href}>{link.label}</ExactNavLink>
                <hr></hr>
              </li>

            ))}
          </ul>
        </div>
      </div>
    </nav>
  )
}

Navbar.propTypes = {
  siteTitle: PropTypes.string,
}

Navbar.defaultProps = {
  siteTitle: ``,
}

export default Navbar
