/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import Favicon from "./../../../images/favicon.svg"

export const seoDataFragment = graphql`
  fragment seoData on PrismicCvswapoutSeoMetadata {
    data {
      meta_title
      meta_description
      social_share_image {
        gatsbyImageData
      }
      analytics_event_name
      analytics_page_title
      analytics_page_type
    }
  }
`
function Seo({ data }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            lang
            cardimageFB
            cardimageTwitter
          }
        }
      }
    `
  )
  const meta = []
  const title = data?.meta_title || site.siteMetadata?.title
  const lang = data?.lang || site.siteMetadata?.lang
  const metaDescription =
    data?.meta_description || site.siteMetadata?.description
  const defaultTitle = data?.meta_title || site.siteMetadata?.title
  const defaultCardImageFB =
    data?.cardimageFB?.publicURL || site.siteMetadata?.cardimageFB
  const defaultCardImageTwitter =
    data?.cardimageTwitter?.publicURL || site.siteMetadata?.cardimageTwitter

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `${defaultTitle}` : null}
      link={[
        { rel: "icon", type: "image/svg+xml", href: Favicon, sizes: "any" },
      ]}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: defaultCardImageFB,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: defaultCardImageTwitter,
        },
      ].concat(meta)}
    />
  )
}

Seo.defaultProps = {
  data: {},
}
Seo.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Seo
