import { graphql, Link, useStaticQuery } from "gatsby"
//import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import "./footer.scss"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      prismicCvswapoutFooter {
        data {
          links {
            href
            label
            open_in_new_tab
          }
          copyright_message
        }
      }
    }
  `)

  return (
    <footer className="cv_footer mt-auto">
      <div className="container">
        <div className="row">
          <div className="col col-12">
            <ul>
              {data.prismicCvswapoutFooter.data.links.map((link, index) => (
                <li key={index}>
                  <Link to={link.href}>{link.label}</Link>
                </li>
              ))}
            </ul>
            &copy; {new Date().getFullYear()},{" "}
            {data.prismicCvswapoutFooter.data.copyright_message}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
